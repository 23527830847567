<!--  -->
<template>
  <div class="home-index">
    <!-- <Breadcrumb :getNav="getNav" :info="query.name"></Breadcrumb> -->
    <!-- <el-row
      style="display: flex; align-items: center; margin-left: -10px"
      :gutter="20"
    >
      <el-col :span="4">
        <el-button
          round
          @click="addClass"
          icon="el-icon-plus"
          type="success"
          dragscroll
          >添加分类</el-button
        >
      </el-col>
      <el-col :span="4"> </el-col>
    </el-row> -->

    <!-- 任务列表 -->
    <TaskInfo
      class="task-info"
      @addClass="addClass"
      v-loading="loading"
      :table="table"
      :loading="loading"
      ref="taskinfo"
    >
    </TaskInfo>

    <!-- type1=增加分类 2=增加任务 3=编辑分类  -->
    <!-- <TaskAdd
      :options="options"
      :taskEmplpyee="taskEmplpyee"
      :section="section"
      :menuList="menuList"
      :addId="addId"
      :type="type"
      v-if="openAdd"
    >
    </TaskAdd> -->
    <TaskAdd
      :options="options"
      :taskEmplpyee="taskEmplpyee"
      :section="section"
      :addId="addId"
      :type="type"
      v-if="openAdd"
    >
    </TaskAdd>
    <div class="diabox">
      <el-dialog title="任务" class="dia" v-dialogDrag :visible.sync="openTask">
        <task-detail
          @getTaskDetail="getTaskDetail"
          @getChatList="getChatList($event)"
          @sendMsg="sendUMsg($event)"
          @getTaskB="getListTaskStairB"
          @pageplus="pagePlus()"
          @editChat="editChat($event)"
          @delChat="delChat($event)"
          @updatepage="updatepage()"
          :propsLoading="infoLoading"
          :info="taskInfo"
          :flag="false"
          :chatList="chatList"
          :atStaffList="atStaffList"
          ref="detail"
        ></task-detail>
      </el-dialog>
    </div>
    <!-- 弹出层 -->
    <van-popup closeable v-model="openMobileTask">
      <div class="vanpop">
        <mobile-task-detail
          @getChatList="getChatList($event)"
          @getTaskDetail="getTaskDetail"
          @sendMsg="sendUMsg($event)"
          @editChat="editChat($event)"
          @delChat="delChat($event)"
          :info="taskInfo"
          :propsLoading="infoLoading"
          :flag="true"
          :chatList="chatList"
          :atStaffList="atStaffList"
          ref="detail"
        ></mobile-task-detail>
      </div>
    </van-popup>
    <!-- 任务分段 -->
    <TaskLev
      ref="TaskLev"
      v-if="openLev"
      :settingInfoId="settingInfoId"
    ></TaskLev>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  Employee,
  listTaskStairB,
  delTaskStair,
  prioritySection,
  getMenuList,
  taskEmployee,
} from '@/api/taskall'
import { getList, getAdd, getAt, getEdit, getWithdraw } from '@/api/chat.js'
import { infoTaskA, taskmodelAccomplish, delTask } from '@/api/taskall'
import TaskInfo from '@/components/Task/TaskInfo.vue'
import TaskDetail from '@/components/Task/TaskDetail.vue'
import MobileTaskDetail from '../../../components/MobileTaskDetail.vue/MobileTaskDetail.vue'
import TaskDia from '@/components/Task/TaskDia.vue'
import TaskLev from '@/components/Task/TaskLev.vue'
import TaskAdd from '@/components/Task/TaskAdd.vue'
import { getNavList } from '@/api/home'
import lodash from 'lodash'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    TaskInfo,
    TaskDia,
    TaskLev,
    TaskAdd,
    TaskDetail,
    MobileTaskDetail,
  },
  data() {
    //这里存放数据
    return {
      taskInfo: {},
      chatList: [],
      atStaffList: [],
      addId: {
        pid: '',
        order: '',
        id: '',
        name: '',
        users: [],
      },
      loading: false,
      taskState: {},
      settingInfoId: '',
      idInfo: {},
      openLev: false,
      openTask: false,
      openMobileTask: false,
      openAdd: false,
      type: 1,
      emp: {
        id: '',
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      pageInfo: {
        id: '',
        page: 1,
        limit: 4,
      },
      allNumber: 0,
      minInfo: {},
      taskdialog: false,
      name: {
        value: 100,
      },
      section: [],
      checked: false,
      getNav: [{ path: '/taskall', name: '任务中心' }],
      table: [],
      query: {},
      del_time: {},
      options: [],

      form: {},
      menuList: [],
      taskEmplpyee: [],
      taskId: null,
      infoLoading: false,
      pageflag: false,
    }
  },
  //监听属性 类似于data概念
  computed: {
    socketMsgs() {
      return this.$websocket.getters.socketMsgs
    },
  },
  //监控data中的数据变化
  watch: {
    openTask(newVal) {
      if (newVal === false) {
        sessionStorage.setItem('openid', '')
      }
    },
    chatList(oldVal, newVal) {
      //   console.log('chatlist changed')
      if (oldVal.length !== newVal.length) {
        this.$refs.detail.scrollToBottom()
      }
    },
  },
  //方法集合
  mounted() {},
  methods: {
    updatepage() {
      this.getListTaskStairB()
    },
    toDoSocket: function(data) {
      console.log(data)
      console.log(data.indexOf('logout'))
      if (data.indexOf('logout') === -1) {
        data = JSON.parse(data)
        console.log('木有logout')
        if (data.type === 'init') {
          console.log(this.$websocket)
          let ids = JSON.stringify(this.groupids)
          console.log(ids)
          let obj = {
            fromid: sessionStorage.getItem('uid'),
            type: 'bind',
            groupids: ids,
          }
          let strObj = JSON.stringify(obj)
          this.$websocket.state.ws.send(strObj)
          this.getChatList()
        } else if (data.type === 'text') {
          // 发消息
          console.log('text')
          this.getChatList()
        }
      } else {
        this.$websocket.commit('reconnect')
        console.log('重连咯')
      }

      if (data.success) {
        console.log(data)
      }
    },
    async editChat(info) {
      const { data: res } = await getEdit(info)
      console.log(res)
      if (res.code === 0) {
        this.getNavList()
        this.getChatList()
        this.$message.success('编辑消息成功')
      } else {
        this.$message.error(res.message)
      }
    },
    async delChat(id) {
      const { data: res } = await getWithdraw({ id: id })
      console.log(res)
      if (res.code === 0) {
        this.getNavList()
        this.getChatList()
        this.$message.success('撤回消息成功！')
      } else {
        this.$message.error(res.message)
      }
    },
    // 发送信息
    async sendUMsg(q) {
      console.log(q)
      let that = this
      //  q.fromid = sessionStorage.getItem('uid')
      const strq = JSON.stringify(q)
      let groupid = q.pid
      let obj = {
        type: 'say',
        data: strq,
        groupid: groupid,
      }
      let qq = JSON.stringify(obj)
      console.log(that.$websocket.state.ws)
      if (that.$websocket.state.ws.readyState === 1) {
        that.$websocket.state.ws.send(qq)
      } else {
        if (that.$websocket.state.ws.readyState === 0) {
          console.log('网络缓慢，正在尝试发送中...')
          that.$message.error('网络缓慢，正在尝试发送中...')
          that.$websocket.commit('reconnect')
        } else if (
          that.$websocket.state.ws.readyState === 2 ||
          that.$websocket.state.ws.readyState === 3
        ) {
          console.log('网络缓慢，正在尝试发送中...')
          that.$message.error('网络缓慢，正在尝试发送中...')
          that.$websocket.commit('reconnect')
        }
      }
      this.q = q
      const { data: res } = await getAdd(q)
      // console.log(res)
      if (res.code === 0) {
        this.getNavList()
        this.getChatList()
        that.$message.success('发布消息成功')
        this.$store.commit('updateTextCache', {
          text: '',
          id: this.taskInfo.id,
        })
        setTimeout(() => {
          that.$refs.detail.scrollToBottom()
        }, 600)
      } else {
        this.$message.error(res.message)
      }
    },
    // 获取左侧导航菜单列表
    async getNavList() {
      console.log('菜单')
      let info = await getNavList()
      info = info.data.data
      this.$store.commit('getNavigation', info)
      //   console.log(111);
    },
    // 获取@的人员列表
    async getAtList() {
      const { data: res } = await getAt({ id: this.taskInfo.id })

      // console.log(res)
      if (res.code === 0) {
        this.atStaffList = res.data
        this.$refs.detail.clearInput()
      } else {
        this.$message.error(res.message)
      }
    },
    // 获取聊天列表
    async getChatList() {
      //   const { data: res } = await getList({ pid: this.taskInfo.id, entire: 1 })
      //   // console.log(res)
      //   if (res.code === 0) {
      //     this.chatList = res.data
      //   }
      console.log('chatlist')

      if (
        window.sessionStorage.getItem('radio') === '0' ||
        window.sessionStorage.getItem('radio') == '1'
      ) {
        //  console.log('0 1')
        const { data: res } = await getList({
          pid: this.taskInfo.id,
          entire: Number(window.sessionStorage.getItem('radio')),
        })
        if (res.code === 0) {
          this.chatList = res.data
          this.infoLoading = false
        }
      } else if (window.sessionStorage.getItem('radio') === '2') {
        // 仅附件
        //   console.log('2')
        const { data: res } = await getList({
          pid: this.taskInfo.id,
          entire: 1,
        })
        if (res.code === 0) {
          if (res.data && res.data.length !== 0) {
            this.chatList = res.data.filter((item) => {
              return item.file !== null
            })
          }
          this.infoLoading = false
        }
      }
    },
    async getTaskDetail(id, flag, flag2) {
      //console.log(2222)
      this.infoLoading = true
      const res = await infoTaskA({ id: id })
      console.log(res)
      if (res.data.code === 1) {
        this.taskInfo = res.data.data
        this.getAtList()
        this.getChatList()
        if (flag === true) {
          this.getNavList()
        }
        // this.getWsMessage()
      } else {
        this.$message.error(res.data.message)
      }

      if (flag2 === true) {
        console.log(this.$refs.detail)
        if (this.$refs.detail !== undefined) {
          this.$refs.detail.clearFile()
        }
      }
      let textCache = JSON.parse(localStorage.getItem('textCache'))
      if (textCache) {
        this.$store.commit('setTextCache', textCache)
      }
      this.$refs.detail.sendQuery.content = ''
      if (textCache && textCache.length > 0) {
        textCache.forEach((item) => {
          if (item.id === this.taskInfo.id) {
            if (item.text !== '') {
              console.log(item.text)
              this.$refs.detail.sendQuery.content = item.text
            }
          }
        })
      }
    },
    //增加事件
    async addClass(item) {
      this.type = 1
      this.emp.id = this.query.id
      this.getEmployee()

      this.openAdd = true
    },

    //增加任务
    async addtask(item) {
      this.openAdd = true
      this.type = 2
      console.log(item.id)
      this.addId.pid = item.id
      let { data: taskEmployee1 } = await taskEmployee({ id: item.id })
      this.taskEmplpyee = taskEmployee1.data
    },
    //编辑分类
    async editClass(form) {
      this.emp.id = this.query.id
      this.getEmployee()
      this.openAdd = true
      this.type = 3

      this.addId.id = form.id
      this.addId.name = form.name

      console.log(form.users)
      let users = lodash.cloneDeep(form.users)
      console.log(users)
      for (let i = 0; i < users.length; i++) {
        users[i] = parseInt(users[i])
        // console.log(users[i])
      }
      console.log(users)
      this.addId.users = users

      this.addId.order = form.order
      let { data: taskEmployee1 } = await taskEmployee({ id: form.id })
      this.taskEmplpyee = taskEmployee1.data
    },
    // 删除任务
    async delTask(item) {
      console.log(item)
      const { data: res } = await delTask({ task_id: item.id })
      if (res.code === 1) {
        this.$message.success(res.message)
        this.getListTaskStairB()
      } else {
        this.$message.error(res.message)
      }
    },
    // 删除分类
    async delClass(form) {
      this.$confirm('确认删除该分类？')
        .then(async (_) => {
          const { data: res } = await delTaskStair({ id: form.id })
          console.log(res)
          if (res.code === 0) {
            this.$message.success('删除分类成功！')
            this.getListTaskStairB()
          } else {
            this.$message.error(res.message)
          }
          done()
        })
        .catch((_) => {})
    },
    //修改每页数量
    sizeChange(value) {
      //console.log(value);
      this.pageInfo.limit = value
      this.getListTaskStairB()
    },
    //分页切换
    pageChange(value) {
      this.pageInfo.page_code = value
      this.getListTaskStairB()
    },
    edit() {},
    // 完成任务/取消完成任务
    async checkbox(e, id, state) {
      console.log(state)
      if (state === 1 || state === 0) {
        // 未完成
        //   console.log(id)
        let form = { id: id }
        let { data: res } = await taskmodelAccomplish(form)
        if (res.code === 0) {
          this.$message.success('已完成该任务！')
          this.getListTaskStairB()
        } else {
          this.$message.error(res.message)
          this.getListTaskStairB()
        }
      }
    },
    inputNumber(item) {
      //console.log(item);
      //console.log(this.userArr);
    },
    changeNumber(item) {
      //console.log(item);
    },
    //获取人员
    async getEmployee() {
      let info = await Employee(this.emp)

      let section = await prioritySection()
      //  let menuList = await getMenuList()

      this.options = info.data.data
      this.section = section.data.data
      //this.menuList = menuList.data.data
      //console.log(this.menuList);
    },
    //获取列表
    async getListTaskStairB(flag) {
      if (flag) {
        this.pageInfo = {
          page: 1,
          limit: 9999,
        }
        this.pageInfo.id = this.query.id
        this.loading = true
        let info = await listTaskStairB(this.pageInfo)
        if (!info.data.data.length) {
          this.loading = false
          return
        }
        this.table = info.data.data

        this.loading = false
      } else {
        this.pageInfo.id = this.query.id
        this.pageInfo.page = 1

        this.pageInfo.limit = this.$store.state.taskPageInfo.limit

        this.loading = true

        let info = await listTaskStairB(this.pageInfo)
        if (!info.data.data.length) {
          this.loading = false
          return
        }
        this.table = info.data.data

        this.loading = false
      }
    },
    async pagePlus() {
      if (this.pageflag !== true) {
        this.pageInfo.page++

        this.$refs.taskinfo.spinnerloading = true
        let info = await listTaskStairB(this.pageInfo)
        if (info.data.data.length > 0) {
          this.table = [...this.table, ...info.data.data]
          this.$store.commit('setPageInfo', {
            page: this.pageInfo.page,
            limit: this.table.length,
            id: this.pageInfo.id,
          })
          this.$refs.taskinfo.spinnerloading = false
        } else {
          console.log('没有数据了')
          this.$refs.taskinfo.spinnerloading = false

          this.pageflag = true
        }
      }
    },
    //根据屏幕判断每一页
    setData() {
      this.query = this.$route.query
      this.form.pid = this.query.id
      let win = document.body.clientWidth
      //console.log(win / 320);
      win = win - 260
      win = win / 320
      //console.log(win.toFixed(0));
      this.$store.state.taskPageInfo.limit = parseInt(win.toFixed(0))
      this.pageInfo.limit = parseInt(win.toFixed(0))
      this.getListTaskStairB()
    },
    //计算任务总分比
    comChange() {
      let info = this.minInfo.percentage
      let number = 0
      console.log('info')
      console.log(info)
      info.forEach((item) => {
        number += item.percentage
      })
      this.allNumber = number
    },
    //整合任务占比
    taskCum2() {
      let info = this.minInfo.percentage
      info.forEach((item) => {
        item.percentage = Math.floor((item.percentage / this.allNumber) * 100)
      })
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.setData()
    this.getEmployee()
    //this.getChatList()
    //this.getAtList()
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）

  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.home-index > ::-webkit-scrollbar-thumb {
  background-color: #9990;
}
.task-info {
  /* min-height: 500px; */
  min-height: calc(100vh - 100px);
  margin-left: 0 !important;
}
.home-index >>> .el-button.is-round {
  padding: 12px 15px 11px;
}
.home-index >>> .el-icon-plus:before {
  margin-left: -3px;
}
.dialog-detail {
  height: 60vh;
  /* overflow-y: scroll; */
}
.dia >>> .el-dialog__body {
  padding: 0 0px 0px 17px;
  height: 75vh;
}
.home-index >>> .el-dialog {
  border-radius: 10px;
}
/* .dia >>> .el-dialog__headerbtn {
  font-size: 23px;
  top: 5px;
  right: 17px;
}  */
.diabox >>> .el-dialog__headerbtn {
  font-size: 22px;
  /* border: 1px solid #909399; */
  border: 2px solid #909399;
  border-radius: 50%;
  height: 26px;
  line-height: 25px;
}
.diabox >>> .el-dialog__headerbtn:hover {
  border: 2px solid #409eff;
}
.vanpop {
  width: 90vw;
  height: 90vh;
}
.home-index >>> .van-popup {
  border-radius: 10px;
}
</style>
