<!--  -->
<template>
  <div class="index">
    <!-- 增加任务分段 -->
    <el-dialog
      v-dialogDrag
      title="增加任务分段"
      :visible.sync="dialogFormVisibleTask2"
      :width="diaglogWidth"
    >
      <el-form :model="form">
        <el-form-item
          v-if="addtype == 1 || addtype == 2"
          label="分段名称"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="settingInfo.reason"
            autocomplete="off"
            placeholder="输入分段名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <table class="tab" v-if="addtype == 3">
        <tr v-for="(item, index) in list.score" :key="index">
          <td class="tab-tit">{{ item.name }}</td>
          <td>
            <el-rate v-model="item.score" :max="6" :allow-half="false">
            </el-rate>
          </td>
        </tr>
      </table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleTask2 = false">取 消</el-button>
        <el-button type="primary" @click="addReason()" v-if="addtype == 1"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="delReason(settingInfo, 2)"
          v-if="addtype == 2"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="delReason(settingInfo, 3)"
          v-if="addtype == 3"
          >打 分</el-button
        >
      </span>
    </el-dialog>
    <!-- 任务分段 -->
    <el-dialog
      v-dialogDrag
      title="任务分段"
      :visible.sync="dialogFormVisibleTask"
      width="50%"
      @close="closeLev()"
    >
      <el-button
        type="primary"
        @click=";(dialogFormVisibleTask2 = true), (addtype = 1)"
        >添加信息</el-button
      >
      <el-table :data="settingList" style="width: 100%">
        <el-table-column prop="reason" label="名称" width="180">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.state == 1">未完成</el-tag>
            <el-tag type="success" v-if="scope.row.state == 2">已完成</el-tag>
            <el-tag type="warning" v-if="scope.row.state == 3">申请中</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-s-tools"
              @click="editReason(scope.row, 2)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="delReason(scope.row, 1)"
            ></el-button>
            <el-button
              size="mini"
              type="success"
              icon="el-icon-check"
              @click="gradeList(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleTask = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisibleTask = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getAddReason,
  getEditReason,
  getDelReason,
  getTaskreason,
  getPConsent,
  getlistReason,
} from '@/api/taskall'
export default {
  //import引入的组件需要注入到对象中才能使用
  props: {
    settingInfoId: Object,
  },
  components: {},
  data() {
    //这里存放数据
    return {
      list: [],
      form: {
        name: '',
        users: [],
        pid: '',
        priority: '',
      },
      formLabelWidth: '100px',
      addtype: 1,
      dialogFormVisibleTask2: false,
      dialogFormVisibleTask: true,
      settingList: [],
      settingInfo: {
        pid: 0,
        reason: '',
      },
      diaglogWidth: '',
    }
  },
  //监听属性 类似于data概念
  computed: {
    pageWidth() {
      return window.innerWidth
    },
  },
  //监控data中的数据变化
  watch: {
    pageWidth: {
      handler(newVal) {
        if (newVal > 768) {
          this.diaglogWidth = '30%'
        } else {
          this.diaglogWidth = '90%'
        }
      },
      immediate: true,
    },
  },
  //方法集合
  methods: {
    async setting() {
      let info = await getlistReason(this.settingInfoId)
      this.settingList = info.data.data
    },
    //添加任务阶段
    async addReason() {
      this.settingInfo.pid = this.settingInfoId.pid
      let info = await getAddReason(this.settingInfo)
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.setting()
        this.dialogFormVisibleTask2 = false
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    //修改任务分段
    editReason(info) {
      this.addtype = 2
      this.dialogFormVisibleTask2 = true
      this.settingInfo = info
    },
    //获取任务分段
    async gradeList(info2) {
      this.addtype = 3
      this.settingInfo = info2
      let info = await getTaskreason({ pid: this.settingInfo.id })
      if (info.data.code == 0) {
        this.dialogFormVisibleTask2 = true
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
      }
      this.list = info.data.data
      console.log(this.list)
    },
    //删除任务阶段
    async delReason(parms, type) {
      let info
      if (type == 2) {
        info = await getEditReason(this.settingInfo)
      } else if (type == 1) {
        info = await getDelReason({ id: parms.id })
      } else if (type == 3) {
        info = await getPConsent(this.list)
      }
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.setting()
        this.dialogFormVisibleTask2 = false
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    closeLev() {
      this.$parent.openLev = false
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {
    this.setting()
    //  console.log("开始");
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.page {
  margin-top: 20px;
}
.tab {
  width: 100%;
}
.tab .tab-tit {
  width: 100px;
  font-weight: bold;
}
.tab td {
  padding: 5px;
  vertical-align: baseline;
}
@media screen and (max-width: 768px) {
  .index >>> .el-dialog {
    width: 90% !important;
  }
}
</style>
