<!--  -->
<template>
  <div class="index">
    <!-- 增加三级任务与编辑分类，增加分类 -->
    <!-- type1=增加分类 3=编辑分类 2=增加任务 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :width="diaglogWidth"
      append-to-body
    >
      <el-form :model="form" v-if="type == 2">
        <el-form-item label="任务名称" :label-width="formLabelWidth">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="输入任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="intelligent-select1"
          label="执行者"
          :label-width="formLabelWidth"
        >
          <el-select
            :popper-append-to-body="false"
            @change="selectChange"
            v-model="form.users"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-checkbox v-model="checked" @change="selectAll()"
              >全部人员</el-checkbox
            >
            <el-option
              v-for="item in taskEmplpyee"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="参与者"
          class="intelligent-select1"
          :label-width="formLabelWidth"
        >
          <el-select
            :popper-append-to-body="false"
            @change="selectChange1"
            v-model="form.participant"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-checkbox v-model="checked1" @change="selectAll1"
              >全部人员</el-checkbox
            >
            <el-option
              v-for="item in taskEmplpyee"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-row v-if="diaglogWidth === '50%'">
          <el-col :span="12">
            <el-form-item label="开始时间" :label-width="formLabelWidth">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
                v-model="form.add_time"
                :default-value="defaultAddTime"
                type="datetime"
                placeholder="开始时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间" :label-width="formLabelWidth">
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.del_time"
                :default-value="defaultEndTime"
                type="datetime"
                placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="diaglogWidth === '90%'">
          <el-form-item label="开始时间" :label-width="formLabelWidth">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.add_time"
              :default-value="defaultAddTime"
              type="datetime"
              placeholder="开始时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row v-if="diaglogWidth === '90%'">
          <el-form-item label="结束时间" :label-width="formLabelWidth">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.del_time"
              :default-value="defaultEndTime"
              type="datetime"
              placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-form-item
          label="优先级"
          class="intelligent-select1"
          :label-width="formLabelWidth"
        >
          <el-select
            :popper-append-to-body="false"
            v-model="form.priority"
            style="width: 100%"
            placeholder="选择优先级"
            clearable
          >
            <el-option
              v-for="item in section"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="菜单页面" :label-width="formLabelWidth">
          <el-cascader
            :options="menuList"
            :props="optionProps"
            v-model="form.department"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item> -->
        <el-button type="warning" size="small" @click="changeTask"
          >设置任务占比</el-button
        >
      </el-form>
      <el-form :model="form" v-if="type == 3">
        <el-form-item label="选择人员">
          <el-select
            v-model="form.users"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="输入分类名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="排序">
          <el-input
            v-model="form.order"
            type="number"
            autocomplete="off"
            placeholder="升序排序，1排在在最前"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <el-form :model="form" :rules="rules" v-if="type == 1">
        <el-form-item prop="users" label="选择参与者">
          <el-select
            v-model="form.users"
            multiple
            style="width: 100%"
            placeholder="请添加参与者"
            clearable
            @change="selectChange2"
          >
            <el-checkbox v-model="checked2" @change="selectAll2"
              >全部人员</el-checkbox
            >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name" label="分类名称">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="输入分类名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="排序">
          <el-input
            v-model="form.order"
            type="number"
            autocomplete="off"
            placeholder="升序排序，1排在在最前"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer" v-if="this.type == 1">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          :disabled="disableBtn"
          type="primary"
          @click="getAddTaskStair()"
          >提 交</el-button
        >
      </div>
      <div slot="footer" class="dialog-footer" v-if="this.type == 2">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="disableBtn" @click="getAddtask()"
          >确 定</el-button
        >
      </div>
      <div slot="footer" class="dialog-footer" v-if="this.type == 3">
        <el-button :disabled="disableBtn" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="getEditTaskStair()">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 设置增加任务占比 -->
    <el-dialog
      v-dialogDrag
      width="660px"
      title="设置任务占比"
      :visible.sync="innerVisible"
    >
      <div class="circle">
        <div
          v-for="(item, index) in form.percentage"
          :key="index"
          class="circle-info"
        >
          <avue-slider
            v-model="item.percentage"
            :max="100"
            :min="0"
            show-input
          ></avue-slider>
          <span style="margin: 10px 0">{{ item.name }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { taskmodelAddTaskr, addTaskStair, editTaskStair } from '@/api/taskall'

//这里可以导入其selectAll2他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    addId: Object,
    type: Number,
    options: Array,
    section: Array,
    menuList: Array,
    taskEmplpyee: Array,
  },
  data() {
    //这里存放数据
    return {
      optionProps: {
        emitPath: false,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      rules: {
        name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
        users: [{ required: true, message: '请选者参与者', trigger: 'blur' }],
      },
      checked: false,
      checked1: false,
      checked2: false,
      formLabelWidth: '100px',
      title: '分类信息',
      dialogFormVisible: true,
      form: {
        name: '',
        users: [],
        pid: '',
        priority: '',
        percentage: [],
      },
      userArr: [],
      userNumber: [],
      innerVisible: false,
      department: '',
      disableBtn: false,
      defaultEndTime: '',
      defaultAddTime: '',
      diaglogWidth: '',
    }
  },
  //监听属性 类似于data概念
  computed: {
    pageWidth() {
      return window.innerWidth
    },
  },
  //监控data中的数据变化
  watch: {
    pageWidth: {
      handler(newVal) {
        if (newVal > 768) {
          this.diaglogWidth = '50%'
        } else {
          this.diaglogWidth = '90%'
        }
      },
      immediate: true,
    },
  },
  //方法集合
  methods: {
    //添加任务
    async getAddtask() {
      this.disableBtn = true
      let info = await taskmodelAddTaskr(this.form)
      this.judge(info)
      this.disableBtn = false
    },
    //添加分类
    async getAddTaskStair() {
      this.disableBtn = true
      console.log(this.form)
      let info = await addTaskStair(this.form)
      this.judge(info)
      this.disableBtn = false
    },
    //修改分类
    async getEditTaskStair() {
      this.disableBtn = true

      let info = await editTaskStair(this.form)
      this.judge(info)
      this.$parent.getListTaskStairB(true)
      this.disableBtn = false
    },
    selectAll() {
      this.form.users = []
      this.form.percentage = []
      if (this.checked) {
        this.taskEmplpyee.map((item) => {
          this.form.users.push(Number(item.id))
          this.form.percentage.push({
            id: item.id,
            name: item.name,
            percentage: 0,
          })
        })
      } else {
        this.form.users = []
        this.form.percentage = []
      }
    },
    selectAll1() {
      this.form.participant = []
      if (this.checked1) {
        this.taskEmplpyee.map((item) => {
          this.form.participant.push(item.id)
        })
      } else {
        this.form.participant = []
      }
    },
    selectAll2() {
      this.form.users = []
      if (this.checked2) {
        this.options.map((item) => {
          console.log(Number(item.id))
          this.form.users.push(Number(item.id))
        })
      } else {
        this.form.users = []
      }
    },
    //判断接口返回状态
    judge(info) {
      if (info.data.code != 0) {
        this.$message({
          message: info.data.message,
          type: 'warning',
        })
      } else if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })

        this.dialogFormVisible = false
        this.$parent.getListTaskStairB()
        this.userClose()
        this.loading = false
      }
    },
    userClose() {
      this.$parent.openAdd = false
    },
    selectChange(info) {
      //console.log(this.form)
      if (info.length === this.taskEmplpyee.length) {
        this.checked = true
      } else {
        this.checked = false
      }
      let userNumber = []
      console.log(userNumber)
      info.forEach((element) => {
        let name = this.taskEmplpyee.find((item) => item.id == element)
        name.percentage = 0
        this.userNumber.push(name.percentage)
        userNumber.push(name)
      })
      this.form.percentage = userNumber
    },
    selectChange1(info) {
      if (info.length === this.taskEmplpyee.length) {
        this.checked1 = true
      } else {
        this.checked1 = false
      }
    },
    selectChange2(info) {
      console.log(info.length)
      console.log(this.options.length)
      if (info.length === this.options.length) {
        this.checked2 = true
      } else {
        this.checked2 = false
      }
      let userNumber = []
      info.forEach((element) => {
        let name = this.options.find((item) => item.id == element)
        name.percentage = 0
        this.userNumber.push(name.percentage)
        userNumber.push(name)
      })
      console.log(userNumber)
      this.form.percentage = userNumber
    },
    //设置任务占比
    changeTask() {
      this.innerVisible = true
    },
    // 根据组件type选择不同状态
    setType(type) {
      if (type == 1) {
        this.title = '分类信息'
        this.form.pid = this.$route.query.id
      }
      if (type == 2) {
        this.title = '任务信息'
        let form = {
          name: '',
          users: [],
          pid: this.addId.pid,
          add_time: '',
          del_time: '',
          percentage: [],
          participant: [],
        }
        this.form = form
      }
      if (type == 3) {
        this.title = '编辑分类'
        this.form.id = this.addId.id
        this.form.name = this.addId.name
        this.form.users = this.addId.users
        this.form.order = this.addId.order
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    const time = new Date()
    // console.log(this.$moment(time).format('YYYY-MM-DD'))
    const today = this.$moment(time).format('YYYY-MM-DD')
    this.defaultAddTime = today + ' ' + '09:00:00'
    this.defaultEndTime = today + ' ' + '18:00:00'
  },
  beforeMount() {
    this.setType(this.type)
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.form.add_time = this.defaultAddTime
    this.form.del_time = this.defaultEndTime
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {
    //console.log('销毁')
  }, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.el-checkbox {
  margin-left: 20px;
  height: 34px;
  line-height: 34px;
  padding-right: 10px;
}
.intelligent-select1 >>> .el-select-dropdown {
  position: absolute !important;
  left: 0px !important;
}
@media screen and (max-width: 768px) {
  .index >>> .el-dialog {
    width: 90% !important;
    margin-top: 3vh !important;
    border-radius: 10px;
  }
}
</style>
