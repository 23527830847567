<!--  -->
<template>
  <!-- 任务详情 -->
  <el-dialog
    v-dialogDrag
    top="50px"
    class="info-pad"
    title="任务详情"
    :visible.sync="taskdialog"
    @close="userClose()"
    style="min-width: 1100px; padding: 0px"
    width="90%"
    append-to-body
  >
    <!-- 功能栏 -->
    <div class="info-log">
      <!-- 左侧 -->
      <div class="info-left">
        <el-form :model="form" label-position="left" size="small ">
          <el-form-item label="状态" :label-width="formLabelWidth">
            <div v-if="minInfo.state != 1">
              <el-button
                type="warning"
                size="mini"
                @click="changeState"
                v-if="minInfo.state == 0"
                >点击完成</el-button
              >
              <el-button
                type="success"
                size="mini"
                v-if="minInfo.state == 2"
                disabled
                >已完成</el-button
              >
            </div>
            <el-button type="info" size="mini" v-else @click="openTask"
              >开始任务</el-button
            >
          </el-form-item>
          <el-form-item label="优先级" :label-width="formLabelWidth">
            <el-select
              @change="changePrio"
              v-model="minInfo.priority"
              style="width: 100%"
              placeholder="选择优先级"
              clearable
            >
              <el-option
                v-for="item in section"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务名称" :label-width="formLabelWidth">
            <el-input
              type="textarea"
              @change="changeName"
              v-model="minInfo.name"
              autocomplete="off"
              placeholder="输入任务名称"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人" :label-width="formLabelWidth">
            <el-select
              @change="changePrincipal"
              v-model="minInfo.u_id"
              style="width: 100%"
              placeholder="请选择添加人员"
              clearable
            >
              <el-option
                v-for="item in form"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="执行者" :label-width="formLabelWidth">
            <el-select
              size="small"
              @change="selectChange2"
              v-model="minInfo.users"
              multiple
              style="width: 100%"
              placeholder="请选择添加人员"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="参与者" :label-width="formLabelWidth">
            <el-select
              @change="selectChange2"
              v-model="minInfo.participant"
              multiple
              style="width: 100%"
              placeholder="请选择添加人员"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间" :label-width="formLabelWidth">
            <el-date-picker
              disabled
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="minInfo.add_time"
              type="datetime"
              placeholder="开始时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" :label-width="formLabelWidth">
            <el-date-picker
              v-if="minInfo.apply == 1"
              @change="changeTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="minInfo.del_time"
              type="datetime"
              placeholder="结束时间"
            >
            </el-date-picker>
            <el-date-picker
              v-if="minInfo.apply == 2"
              disabled
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="minInfo.del_time"
              type="datetime"
              placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <!-- 右侧 -->
      <div class="info-right">
        <div class="info-right-title">
          <el-collapse v-model="activeName">
            <el-collapse-item title="任务信息" name="1">
              <div class="father-coll">
                <!-- 未查阅 已查阅 -->
                <div class="over-task">
                  <div>
                    已查阅:
                    <el-tag
                      style="margin-right: 2px"
                      size="small"
                      v-for="(item, index) in minInfo.examine_b"
                      :key="index"
                      >{{ item }}</el-tag
                    >
                  </div>
                  <div style="margin-top: 5px">
                    未查阅:
                    <el-tag
                      style="margin-right: 2px"
                      size="small"
                      type="info"
                      v-for="(item, index) in minInfo.examine_a"
                      :key="index"
                      >{{ item }}</el-tag
                    >
                  </div>
                </div>
                <!-- 任务占比 -->
                <div class="task-per">
                  <div class="info-task-sli" v-if="pie">
                    <div
                      v-for="(item, index) in minInfo.percentage"
                      :key="index"
                      class="sli-name"
                    >
                      <span>{{ item.name }}</span>
                      <el-slider
                        v-model="item.percentage"
                        show-input
                        @input="comChange"
                      >
                      </el-slider>
                    </div>
                  </div>
                  <div class="pie" v-if="!pie">
                    <Pie :info="minInfo.percentage"></Pie>
                  </div>
                  <el-button @click="changePie" size="small">
                    <span v-show="pie"> 切换饼图 </span>
                    <span v-show="!pie"> 切换编辑 </span>
                  </el-button>

                  <el-button size="small" @click="taskChange"
                    >提交任务分配</el-button
                  >
                  <el-button size="small" @click="taskCum"
                    >给剩余人员分配</el-button
                  >
                  <span> {{ allNumber }}% </span>
                  <span
                    style="font-size: 12px; color: red"
                    v-show="allNumber > 100"
                  >
                    任务分配比例异常
                  </span>
                </div>
              </div>
            </el-collapse-item>
            <!-- 聊天阶段 -->
            <el-collapse-item title="任务动态" name="3">
              <Chat :pid="idInfo.id" />
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Pie from '@/components/Pie.vue'
import Chat from '@/components/Task/chat/chat.vue'
import {
  taskmodelAccomplish,
  taskmodelConfirmTask,
  infoTaskA,
  taskmodelEdit,
  checkTime,
} from '@/api/taskall'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Pie, Chat },
  props: {
    options: Array,
    section: Array,
    idInfo: Object,
  },
  data() {
    //这里存放数据
    return {
      allNumber: 0,
      minInfo: {},
      getInfo: {
        //搜索框
        page_code: 1,
        page_num: 20,
      },
      pie: true,
      taskdialog: true,
      textList: [],
      form: {
        name: '',
        users: [],
        pid: '',
        priority: '',
      },
      activeName: ['1', '2', '3'],
      formLabelWidth: '100px',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getTask(id, id2) {
      this.$parent.getEmployee()
      let info = await infoTaskA({
        id: id,
      })
      this.minInfo = info.data.data
      // console.log(this.minInfo);
      this.del_time = this.minInfo.del_time
    },
    changePie() {
      this.pie = !this.pie
    },
    userClose() {
      this.$parent.openTask = false
    },
    //修改优先级
    async changePrio() {
      let form = {
        id: this.minInfo.id,
        priority: this.minInfo.priority,
      }
      let info = await taskmodelEdit(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    //修改名称
    async changeName() {
      let form = {
        id: this.minInfo.id,
        name: this.minInfo.name,
      }
      let info = await taskmodelEdit(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    //修改执行者，参与者
    async selectChange2() {
      let info = await taskmodelEdit({
        id: this.minInfo.id,
        participant: this.minInfo.participant,
        users: this.minInfo.users,
      })
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    async changeTime() {
      let form = {
        id: this.minInfo.id,
        del_time: this.minInfo.del_time,
      }
      let info = await checkTime(form)
      console.log('修改名称')
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    comChange() {
      let info = this.minInfo.percentage
      let number = 0
      //console.log("info");
      //console.log(info);
      info.forEach((item) => {
        number += item.percentage
      })
      this.allNumber = number
    },
    async taskChange() {
      let info = await taskmodelEdit({
        id: this.minInfo.id,
        percentage: this.minInfo.percentage,
      })
      if (this.judge(info)) {
        this.getTask(this.idInfo.id, this.idInfo.id2)
      }
    },
    taskCum() {
      if (this.allNumber > 100) {
        return
      }
      let info = this.minInfo.percentage
      let cNumber = 0
      let allNumber = this.allNumber
      info.forEach((item) => {
        if (item.percentage == 0) {
          cNumber++
        }
      })
      let numInfo = 100 - allNumber
      info.forEach((item) => {
        if (item.percentage == 0) {
          item.percentage = Math.floor(numInfo / cNumber)
          console.log(numInfo)
          console.log(cNumber)
          console.log(item.percentage)
        }
      })
    },
    //  开始任务
    async openTask() {
      let form = { id: this.minInfo.id }
      let info = await taskmodelConfirmTask(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    //任务完成
    async changeState() {
      let form = { id: this.minInfo.id }
      let info = await taskmodelAccomplish(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    //修改负责人
    async changePrincipal() {
      let form = {
        id: this.minInfo.id,
        u_id: this.minInfo.u_id,
      }
      let info = await taskmodelEdit(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return false
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getTask(this.idInfo.id, this.idInfo.id2)
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.home-index {
  height: calc(100% - 60px);
}
.info-log {
  width: 100%;
  display: flex;
  border-top: 1px solid #e5e5e5;
}
.info-left {
  min-width: 320px;
  width: 30%;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 20px;
  border-right: 1px solid #e5e5e5;
}
.info-right {
  flex: 1;
}
.info-pad >>> .el-dialog__body {
  padding: 10px !important;
}
.info-right-title {
  padding: 10px;
}
.over-task {
  width: 100%;
  max-height: 100px;
  overflow: auto;
}
.info-task-sli {
  width: 100%;
  max-height: 200px;
  margin-bottom: 10px;
  overflow: auto;
}
.sli-name {
  width: 40%;
  float: left;
  margin-right: 10px;
  margin-left: 15px;
}
/* .info-left >>> .el-input__inner,
.info-left >>> .el-select .el-input.is-focus .el-input__inner,
.info-left >>> .el-textarea__inner {
  border: none;
} */

/* .dynamic-info:hover {
  top: -5px;
  margin-left: 5px;
  width: 99%;
  box-shadow: 1px 1px 5px rgb(38 38 38 / 30%);
  background: #ffff;
} */

.father-coll {
  display: flex;
  justify-content: center;
  align-items: center;
}
.father-coll .over-task {
  flex: 1;
}
.father-coll .task-per {
  flex: 2;
}
</style>
