<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
// import resize from './mixins/resize'

const animationDuration = 6000

export default {
  // mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '110px',
    },
    per1: {},
    per2: {},
    per3: {},
  },
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      //   this.chart = echarts.init(this.$el, 'macarons')
      this.chart = echarts.init(this.$el)
      // var data = [22, 182, 191]
      var bgColor = [
        'rgba(38, 104, 134,0.2)',
        'rgba(233, 43, 1,0.2)',
        'rgba(211, 235, 210,0.5)',
      ]
      var data = [this.per1, this.per2, this.per3]
      var barWidth = 20
      var maxNum = 100
      //   for (var i = 0; i < data.length; i++) {
      //     if (data[i] > maxNum) {
      //       maxNum = data[i]
      //     }
      //   }
      this.chart.setOption({
        grid: {
          top: '0%',
          left: '35%',
          right: '5%',
          bottom: '0%',
        },

        title: {},
        xAxis: {
          show: false,
        },
        yAxis: {
          //   data: data.map((item, index) => `data ${index}`),
          data: ['任务占比', '时间进度', '任务进度'],
          splitLine: {
            show: false,
          },
          axisLabel: {
            textStyle: { fontSize: '80%', color: '#000' },
          },
          axisLine: {
            show: false,
          },
          axisTick: false,
        },
        series: [
          {
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              borderRadius: 10,
              color: 'rgba(180, 180, 180, 0.2)',
            },
            barWidth: barWidth,
            zlevel: 2,
            data: data.map(function(item) {
              return {
                value: item,
                maxNum: maxNum,
              }
            }),
            label: {
              show: false,
              position: 'inside',
              distance: 80,
              align: 'left',
              offset: [-20, 2],
              //   verticalAlign: 'middle',
              formatter: function(params) {
                // return params.data.realValue;
                var percent =
                  Number(
                    (params.data.value / params.data.maxNum) * 100
                  ).toFixed(2) + '%'
                return percent
              },
              color: '#606266',
              fontWeight: 900,
              fontSize: 12,
            },
            itemStyle: {
              borderRadius: 10,
              //   color: new echarts.graphic.LinearGradient(0, 1, 1, 1, [
              //     { offset: 0, color: '#395CFE' },
              //     { offset: 1, color: '#2EC7CF' },
              //   ],
              //   ),
              color: function(params) {
                var colorList = [
                  ['#2EC7CF', '#395CFE'], // 任务占比
                  ['#fdb71d', '#fb362b'], // 时间进度
                  ['#addcaa', '#3ba1a3'],
                ]
                var index = params.dataIndex
                if (params.dataIndex >= colorList.length) {
                  index = params.dataIndex - colorList.length
                }
                return new echarts.graphic.LinearGradient(0, 1, 1, 1, [
                  {
                    offset: 0,
                    color: colorList[index][0],
                  },
                  {
                    offset: 1,
                    color: colorList[index][1],
                  },
                ])
              },
            },
          },
          {
            type: 'bar',
            barWidth: barWidth,
            barGap: '-100%',
            data: data.map(function(item) {
              return {
                realValue: item,
                value: maxNum,
              }
            }),
            // label: {
            //   show: true,
            //   position: 'right',
            //   distance: 80,
            //   align: 'right',
            // //   formatter: function(params) {
            // //     return params.data.realValue + ' 件'
            // //   },
            //   color: '#02afff',
            //   fontSize: 18,
            // },
            itemStyle: {
              borderRadius: 10,
              color: function(params) {
                return bgColor[params.dataIndex]
              },
            },
          },
        ],
      })
    },
  },
}
</script>
