<!--  -->
<template>
  <div class="">
    <!-- 编辑信息 -->
    <el-dialog
    v-dialogDrag
      title="查看图片"
      :visible.sync="innerVisible"
      append-to-body
      :fullscreen="true"
    >
      <div class="image">
        <el-image
          style="width: 30%; height: 30%"
          :src="img"
          fit="fill"
        ></el-image>
      </div>
    </el-dialog>
    <div
      v-for="(item, index) in fileList"
      :key="index"
      class="imglist"
      @click="openImg(item)"
    >
      <el-avatar
        shape="square"
        :size="30"
        :src="item"
        fit="fill"
        :preview-src-list="fileList"
      ></el-avatar>
      <div class="imgsea">查看大图</div>
    </div>
    <div></div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  props: {
    fileList: Array,
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      innerVisible: false,
      img: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    openImg(item) {
      this.innerVisible = true;
      console.log(item);
      this.img = item;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.imglist {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 5px;
  padding: 4px;
  box-shadow: 1px 1px 1px #eee;
  cursor: pointer;
}
.imgsea {
  cursor: pointer;
  margin-left: 20px;
  font-size: 12px;
}
.imglist:hover > .imgsea {
  color: royalblue;
}
.image {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 50px;
  border-radius: 10px;
  background: #eee;
}
</style>