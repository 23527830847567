<!-- 任务列表 -->
<template>
  <div class="task-all">
    <div ref="task-info-a" id="info-task-a" class="task-info-a">
      <draggable
        class="dragele"
        v-model="sortTable"
        @change="change"
        v-bind="dragOptions"
        @start="start"
        @end="end"
        :scroll="true"
        style="display: flex"
        :handle="'.i-title'"
      >
        <!-- <transition-group
          style="display: flex;"
          type="transition"
          :name="!drag ? 'flip-list' : null"
        > -->
        <div
          ref="infocards"
          class="info-card1"
          v-for="item in sortTable"
          :key="item.id"
        >
          <div class="i-title">
            <span class="info-title">
              {{ item.name }}
            </span>
            <span class="icon-edit">
              <i class="el-icon-edit" @click="editClass(item)"></i>
              <span> </span>
              <i @click="deleteClass(item)" class="el-icon-delete"></i>
            </span>
          </div>

          <div @scroll="scrollEventFn1" class="task-card-sc">
            <div class="fordrag">
              <draggable
                style="width: 100%; min-height: 5px"
                v-bind="dragOptions1"
                @change="itemChange"
                @start="itemstart"
                class="list-group"
                group="task"
                v-model="item.sub"
                :handle="'.el-icon-s-operation'"
              >
                <div
                  :class="{ 'task-card': true, grey: item2.state === 2 }"
                  @click="getinfo(item2.id, item.id, true, compoName)"
                  v-for="item2 in item.sub"
                  :key="item2.id"
                >
                  <div class="options">
                    <div style="display: flex; justify-content: center">
                      <el-checkbox
                        @click.native="checkbox($event, item2.id, item2.state)"
                        v-model="item2.state"
                        :true-label="2"
                        :false-label="1"
                      >
                      </el-checkbox>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 3px;
                        margin-left: 1px;
                      "
                    >
                      <el-badge
                        class="mark"
                        v-if="item2.no_read_num !== 0"
                        :value="item2.no_read_num"
                      />
                    </div>
                  </div>
                  <div class="task-title">
                    {{ item2.name }}
                    <div class="all">
                      <div class="task-left">
                        <div class="info-time-all">
                          <div
                            class="info-time"
                            v-if="item2.time_state == 1"
                            style="background-color: #409eff"
                          >
                            {{ item2.del_time }}
                          </div>
                          <div
                            class="info-time"
                            v-if="item2.time_state == 2"
                            style="background-color: #e6a23c"
                          >
                            {{ item2.del_time }}
                          </div>
                          <div class="info-time" v-if="item2.time_state == 3">
                            {{ item2.del_time }}
                          </div>
                        </div>
                        <!-- 优先级 -->
                        <div class="other-info">
                          <!-- 任务已开始0 未开始1 已完成2 -->
                          <div class="task-state">
                            <div class="gourpB">
                              <el-tag
                                size="small"
                                style="margin-right: 5px"
                                type="success"
                                v-if="item2.state === 0"
                                >进行中</el-tag
                              >
                              <el-tag
                                size="small"
                                style="margin-right: 5px"
                                type="info"
                                v-if="item2.state === 1"
                                >未开始</el-tag
                              >
                              <el-tag
                                size="small"
                                style="margin-right: 5px"
                                type="primary"
                                v-if="item2.state === 2"
                                >已完成</el-tag
                              >
                            </div>
                            <div class="groupA">
                              <el-tag
                                size="small"
                                type="danger"
                                v-if="item2.priority_name === '非常紧急'"
                                >非常紧急</el-tag
                              >
                              <el-tag
                                size="small"
                                type="warning"
                                v-else-if="item2.priority_name === '紧急'"
                                >紧急</el-tag
                              >
                              <el-tag
                                size="small"
                                type="primary"
                                v-else-if="item2.priority_name === '普通'"
                                >普通</el-tag
                              >
                              <el-tag size="small" type="info" v-else
                                >较低</el-tag
                              >
                            </div>
                          </div>
                        </div>
                        <!-- percentage 任务占比  stage是任务进度 -->
                        <div class="echarts-bar">
                          <!-- <bar
                        :per1="item2.percentage"
                        :per2="item2.time_formerly"
                        :per3="item2.stage"
                      ></bar>
                      <div class="percentage">
                        <div class="dd">{{ item2.stage }} %</div>
                        <div class="dd">{{ item2.time_formerly }} %</div>
                        <div class="dd">{{ item2.percentage }} %</div>
                      </div> -->

                          <single-bar
                            :per="item2.stage"
                            height="35px"
                          ></single-bar>
                          <div class="percentage">
                            <!-- stage 任务进度 -->
                            <div class="dd">任务进度 {{ item2.stage }}%</div>
                          </div>
                        </div>
                      </div>
                      <div class="task-right">
                        <div class="head-info">
                          <div class="head-img">
                            <el-tooltip
                              effect="dark"
                              :content="
                                changeUname(item2.users_arr, item2.u_name)
                              "
                              placement="top"
                            >
                              <img
                                :src="item2.img"
                                v-imgerror="Img"
                                alt=""
                                width="30"
                                class="image"
                              />
                            </el-tooltip>
                            <div class="uname">{{ item2.u_name }}</div>
                          </div>
                          <div class="task-del">
                            <!-- <i class="el-icon-s-operation" @click.stop="move"></i> -->
                            <i
                              class="el-icon-setting"
                              @click.stop="setting(item2)"
                            ></i>
                            <i
                              v-if="item2.u_id == userId"
                              class="el-icon-circle-close"
                              @click.stop="delTask(item2)"
                            ></i>
                            <i class="el-icon-s-operation"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
          <div>
            <el-button
              style="width: 100%; margin-top: 10px"
              icon="el-icon-plus"
              @click="addtask(item)"
            ></el-button>
          </div>
        </div>
        <div v-show="spinnerloading" class="loading-spinner">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>

        <div class="addclass" v-if="!loading" @click="addClass">+ 添加分类</div>
        <!-- </transition-group> -->
      </draggable>
      <!-- <div class="smaller" @click="changeView()">
        <div>
          <i class="el-icon-minus"></i>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Img from '@/assets/defaultStaffPhoto.png'
import Bar from '@/components/Bar'
import SingleBar from '@/components/SingleBar'
import { changeTaskStairOrder, updateTaskOrder } from '@/api/taskall.js'
import BScroll from '@better-scroll/core'
import Drag from '@/utils/move.js'
import lodash from 'lodash'
import draggable from 'vuedraggable'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Bar,
    draggable,
    SingleBar,
  },
  props: {
    table: Array,
    loading: Boolean,
  },
  data() {
    //这里存放数据
    return {
      time_formerly: 100,
      Img: Img,
      taskId: null,
      userId: sessionStorage.getItem('uid'),
      sortTable: [],
      drag: false,
      scaleFlag: false,
      sortChange: false,
      itemdrag: false,
      pagequery: {
        page: 1,
        limit: 5,
      },
      spinnerloading: false,
      compoName: '',
      infocards: '',
    }
  },
  //监听属性 类似于data概念
  computed: {
    pageWidth() {
      return window.innerWidth
    },
    pageHeight() {
      return window.innerHeight
    },
    dragOptions() {
      return {
        animation: 200,
        scroll: true,

        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scrollSensitivity: 2000, // px, how near the mouse must be to an edge to start scrolling.
        scrollSpeed: 2000, // px
      }
    },
    dragOptions1() {
      return {
        animation: 200,
        scroll: true,
        disabled: false,
        ghostClass: 'ghost',
        scrollSensitivity: 200, // px, how near the mouse must be to an edge to start scrolling.
        scrollSpeed: 2000, // px
      }
    },
  },
  //监控data中的数据变化
  watch: {
    pageWidth: {
      handler(newVal) {
        if (newVal > 768) {
          this.compoName = 'Computer'
        } else {
          this.compoName = 'Mobile'
        }
      },
      immediate: true,
    },
    pageHeight: {
      handler(newVal) {},
    },
    taskId(newVal) {
      if (newVal !== null) {
        let id = Number(this.taskId)
        this.$parent.getTaskDetail(id)
        this.$parent.openTask = true
      }
    },
    table(newVal) {
      this.sortTable = newVal
    },
  },
  //方法集合
  methods: {
    fscrollEvent() {
      let panel = document.querySelector('#info-task-a')
      panel.addEventListener(
        'scroll',
        lodash.debounce(this.scrollEventFn, 500),
        true
      )
    },
    scrollEventFn(e) {
      if (
        e.srcElement.scrollLeft + e.srcElement.clientWidth >
        e.srcElement.scrollWidth - 150
      ) {
        console.log('触发了')

        this.$parent.pagePlus()
      }
    },

    scrollEventFn1(e) {
      //e.srcElement.scrollTop: 滚动条距离页面顶部距离
      //e.srcElement.clientHeight: 滚动页面高度
      //e.srcElement.scrollHeight: 滚动条高度
      //   console.log(e.srcElement.scrollLeft)
      // console.log(e.srcElement.clientWidth)
      //console.log(e.srcElement.scrollWidth)
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >
        e.srcElement.scrollHeight - 50
      ) {
        //-50距离底部50px是触发以下内容
        console.log('触发了')
        // if(this.pageData.pageIndex < this.pageData.pageTotal){
        //     this.pageData.pageIndex ++
        //     this.getUserActionDataFn()
        // }
      }
    },
    changeUname(arr, name) {
      // console.log(name)
      let a = []

      //console.log(arr)
      if (arr.length !== 0) {
        arr.forEach((pp) => {
          a.push(pp.u_name + ' ')
        })
        a = a.join(' ')
        //  console.log(a)
        return a
      } else {
        return name
      }
    },
    addClass() {
      this.$emit('addClass')
    },
    async itemChange(event) {
      let taskid, query
      if (event.added) {
        taskid = event.added.element.id
        console.log('add:', event.added.element.name)
        query = {
          current_id: event.added.element.id,
          pid: null,
        }
      } else if (event.removed) {
        console.log('removed:', event.removed.element.name)

        query = {
          current_id: event.removed.element.id,
          pid: null,
        }
        return
      }

      this.sortTable.forEach((item) => {
        if (item.sub && item.sub.length !== 0) {
          item.sub.forEach(async (task) => {
            if (task.id === taskid) {
              query.pid = item.id
              const { data: res } = await updateTaskOrder(query)
            }
          })
        }
      })
    },

    changeView() {
      this.scaleFlag = !this.scaleFlag
    },
    // 监听拖拽
    async change(event) {
      console.log('spec change')
      console.log(event)
      let query = {
        upper_id: '',
        current_id: event.moved.element.id,
        down_id: '',
      }

      if (event.moved.newIndex === 0) {
        //第一个
        this.sortTable.forEach(async (item, index) => {
          if (item.id === event.moved.element.id) {
            query.down_id = this.sortTable[index + 1].id
          }
        })
        const { data: res } = await changeTaskStairOrder(query)
      } else if (event.moved.newIndex === this.sortTable.length - 1) {
        // 最后一个
        this.sortTable.forEach(async (item, index) => {
          if (item.id === event.moved.element.id) {
            query.upper_id = this.sortTable[index - 1].id
          }
        })
        const { data: res } = await changeTaskStairOrder(query)
      } else {
        //中间
        this.sortTable.forEach(async (item, index) => {
          if (item.id === event.moved.element.id) {
            query.down_id = this.sortTable[index + 1].id
            query.upper_id = this.sortTable[index - 1].id
          }
        })
        const { data: res } = await changeTaskStairOrder(query)
      }

      // this.$parent.editClass(event.moved.element)
    },
    // 开始拖拽
    start(event) {
      console.log('start')

      this.drag = true
    },
    // 结束拖拽
    end(event) {
      console.log('end')

      console.log(event)
      this.drag = false
    },
    itemstart(event) {
      console.log(event)
      this.itemdrag = true
    },
    itemend(event) {
      this.itemdrag = false
    },
    editClass(item) {
      this.$parent.editClass(item)
    },
    deleteClass(item) {
      this.$parent.delClass(item)
    },

    //打开任务详情
    getinfo(item2, item, flag, deviceType) {
      this.$parent.idInfo = { id: item2, ids: item }
      //console.log(item2)
      //console.log(item)
      this.$parent.getTaskDetail(item2, true, flag)
      this.$parent.getListTaskStairB()
      if (deviceType === 'Computer') {
        this.$parent.openTask = true
      } else {
        this.$parent.openMobileTask = true
      }
    },

    tooip() {},
    checkbox(e, id, state) {
      e.stopPropagation()
      if (e.target.tagName === 'INPUT') return
      this.$parent.checkbox(e, id, state)
    },
    setting(item2) {
      this.$parent.settingInfoId = { pid: item2.id }
      this.$parent.openLev = true
    },
    addtask(item) {
      this.$parent.addtask(item)
    },
    async delTask(item) {
      this.$confirm('将该任务放入回收站？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async (_) => {
          this.$parent.delTask(item)
          done()
        })
        .catch((_) => {})
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.fscrollEvent()

    this.sortTable = this.table
    let taskId = sessionStorage.getItem('openid')
    // console.log(taskId)
    if (taskId) {
      this.taskId = taskId
    } else {
      this.taskId = null
    }
    this.infocards = this.$refs.infocards
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {
    sessionStorage.setItem('openid', '')
  }, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.addclass {
  width: 300px;
  background-color: #e8e8e9;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
  height: 36px;
  font-size: 14px;
  line-height: 35px;
}
.spinner {
  margin: 10px auto 0;
  width: 120px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #666;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.addclass:hover {
  color: #409eff;
}
.task-all {
  width: 100%;
  height: calc(100% - 20px);
  /* overflow: auto; */
}
.task-info-a {
  max-width: 100%;
  overflow-y: scroll;
  position: relative;
  display: flex;
  /* display: flex;
  flex-wrap: nowrap;

  flex-shrink: 0; */

  margin-top: 6px;
}
.info-card1::-webkit-scrollbar {
  height: 4px;
}
::-webkit-scrollbar {
  /* width: 4px; */
  height: 10px;
}

@keyframes downscale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes upscale {
  0% {
    transform: scale(1.2);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1.6);
  }
  75% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(2);
  }
}
@-webkit-keyframes upscale {
  0% {
    transform: scale(1.2);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1.6);
  }
  75% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(2);
  }
}
@-webkit-keyframes downscale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.5);
  }
}
#animat1 {
  position: relative;
  animation: downscale 1s;
  animation-direction: alternate; /*轮流反向播放动画。*/
  animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation: downscale 1s; /*Safari and Chrome*/
  -webkit-animation-direction: alternate; /*轮流反向播放动画。*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
}
#animat2 {
  position: relative;
  animation: upscale 1s;
  animation-direction: alternate; /*轮流反向播放动画。*/
  animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation: upscale 1s; /*Safari and Chrome*/
  -webkit-animation-direction: alternate; /*轮流反向播放动画。*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
}
.task-info-a .smaller {
  width: 50px;
  height: 50px;
  line-height: 60px;
  border-radius: 50%;
  border: 1px solid #409eff;
  box-shadow: 0px 0px 5px 4px #409eff;
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
.task-info-a .smaller div {
  text-align: center;
}
.task-info-a .smaller .el-icon-minus {
  font-size: 30px;
  color: #409eff;
  font-weight: 700;
}
.info-card1 {
  width: 300px;
  margin-right: 10px;
  flex-shrink: 0;
  box-sizing: border-box;
  height: calc(100vh - 110px);
  background-color: #e8e8e9;
  padding: 12px;
  border-radius: 15px;
}
.i-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.i-title:hover {
  cursor: url('../../assets/move2.png'), auto;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.task-all >>> .el-loading-mask {
  /* width: 100vw; */
  height: 100%;
  border-radius: 15px;
  /* margin-top: 9px; */
}
@media screen and (max-width: 768px) {
  .task-all >>> .el-loading-mask {
    width: 100vw;
    height: calc(100vh - 245px);
    border-radius: 15px;
    /* margin-top: 9px; */
  }
}
.icon-edit {
  cursor: pointer;
  display: flex;
  color: #666;
  justify-content: center;
  align-items: center;
}
.item {
  border: 1px solid #bbb;
  margin: 10px;
  width: 100px;
  padding: 5px;
}
.icon-edit .el-icon-edit {
  margin-right: 4px;
}
.info-title {
  font-size: 14px;
  font-weight: 700;
  font-family: 'PingFangRegular';
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  color: #595959;
  padding: 0;
  box-sizing: border-box;
}
.task-card {
  position: relative;
  width: 100%;
  white-space: normal;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  padding: 10px 16px 14px 6px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 10px;
  user-select: none;
}
.image {
  margin: 0 8px 0 5px;
  padding: 2px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee;
  vertical-align: middle;
}
.uname {
  font-size: 12px;
  color: #666;

  margin-bottom: 5px;
}
.options {
  position: relative;
  margin-right: 3px;
  top: -4px;
  display: flex;
  width: 35px;
  flex-direction: column;
}
.head-img {
  position: relative;

  top: -2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.task-title {
  word-wrap: break-word;
  font-family: 'PingFangRegular';
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 162px;
  font-size: 14px;
  color: #262626;
  /* margin-top: 2px; */
}
.all {
  display: flex;
  justify-content: space-between;
  /* margin-top: 5px; */
}
.task-right {
  display: flex;
  justify-content: end;
}
.info-time-all {
  margin-top: 5px;
}
.circle {
  width: 100%;
  height: 200px;
  overflow: auto;
}
.circle-info {
  width: 40%;
  float: left;
  margin-right: 10px;
  margin-left: 15px;
}
.info-log {
  width: 100%;
  display: flex;
  border-top: 1px solid #e5e5e5;
}
.task-del {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.info-left {
  width: 30%;
  padding-left: 10px;
  padding-right: 20px;
  border-right: 1px solid #e5e5e5;
}
.info-right {
  flex: 1;
}
.info-pad >>> .el-dialog__body {
  padding: 10px !important;
}
.info-time-all {
  display: flex;
}
.info-time {
  color: #fff;
  background-color: #f56c6c;
  border-radius: 12px;
  margin: 2px 6px 0 0;
  font-size: 12px;
  padding: 3px 10px;
}
.other-info {
  margin-top: 10px;
  margin-bottom: 2px;
}
.other-info .task-state {
  display: flex;
}
.task-state >>> .el-tag--small {
  height: 23px;
  padding: 0 10px;
  border-radius: 12px;
}
.el-icon-setting,
.el-icon-circle-close,
.el-icon-s-operation {
  /* margin: 0 8px 0 5px; */
  padding: 2px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #666;
}
.el-icon-s-operation {
  cursor: url('../../assets/move2.png'), auto;
}
.el-icon-s-operation:hover {
  color: #409eff;
}
.el-icon-setting:hover,
.el-icon-circle-close:hover,
.el-icon-s-operation:hover {
  color: #409eff;
}
.circle-mag {
  margin-top: 10px;
}
.head-info {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.task-card-sc {
  max-height: calc(100vh - 200px);

  /* overflow: auto;
   */
  overflow-y: overlay;
}
.item-back-1 {
  width: 100%;
  height: 40px;
  position: absolute;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #e5e5e5;
}
.middle-item {
  margin: 0 8px;
}
* >>> .el-progress__text {
  font-weight: bold;
  position: relative;
}
* >>> .el-progress-circle {
  margin-bottom: 4px;
}
.item-back {
  position: relative;
  display: inline-block;
  /* background: red; */
  z-index: 999;
}
.options .el-checkbox >>> {
  zoom: 150% !important;
}
.options >>> .el-checkbox__inner {
  border: 1px solid #c0c4cc !important;
}
.options .el-checkbox:last-of-type {
  margin-right: 0px !important;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.echarts-bar {
  /* margin-top: 10px; */
  position: relative;
}
.echarts-bar .percentage {
  color: #fff;
  font-size: 12px;
  /* font-weight: 700; */
  position: absolute;
  top: 1px;
  display: flex;
  flex-direction: column;
  height: 35px;
  width: 162px;
  justify-content: center;
  /* align-items: center; */
}

.echarts-bar .percentage .dd {
  height: 35px;
  line-height: 35px;
  margin-left: 10px;
}
.grey {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
</style>
