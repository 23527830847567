<!--  -->
<template>
  <div class="" @click="stateChange = false">
    <!-- 编辑信息 -->
    <el-dialog
      v-dialogDrag
      width="30%"
      title="编辑信息"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="record.content"
        >
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setEdit()">修改</el-button>
      </div>
    </el-dialog>
    <!-- 上传文件 -->
    <el-dialog
      v-dialogDrag
      width="50%"
      title="编辑信息"
      :visible.sync="innerVisibleImg"
      @close="imgClose"
      append-to-body
    >
      <el-upload
        v-if="form.state == 3"
        :headers="token"
        accept=".jpg,.png"
        :on-remove="imgRemove"
        :limit="3"
        :action="imgUrl"
        list-type="picture"
        :on-exceed="exceed"
        :on-success="imgSuccess"
        name="image"
        :file-list="fileList"
      >
        <el-button size="small" type="primary">上传图片</el-button>
      </el-upload>
      <el-upload
        v-if="form.state == 4"
        :headers="token"
        accept=".jpg,.png"
        :on-remove="imgRemove"
        :limit="3"
        :action="imgUrl"
        :on-exceed="exceed"
        :on-success="imgSuccess"
        name="image"
        :file-list="fileList"
      >
        <el-button size="small" type="primary">上传文件</el-button>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setAdd()">发布</el-button>
      </div>
    </el-dialog>
    <div class="dynamic" ref="Dynamic">
      <div class="more">
        <div class="more-text" v-show="chatInfo.length != 0" @click="setAll">
          <span v-show="chatInfo.length == 5">查看全部</span>
          <span v-show="chatInfo.length != 5">隐藏动态</span>
        </div>
      </div>
      <div v-for="(item, index) in chatInfo" :key="index">
        <!-- 聊天阶段 -->
        <div v-show="item.state == 2" class="card-score-all">
          <div class="card-score-title">阶段评分:</div>
          <div
            class="card-score"
            v-for="(itemChild, index) in item.sub"
            :key="index"
          >
            <img :src="itemChild.img" alt="" width="30" class="image" />

            {{ itemChild.name }}
            {{ itemChild.score }}
          </div>
        </div>
        <!-- 聊天内容 -->
        <div
          v-show="item.state != 2"
          :style="{ background: item.state == 3 ? '' : '' }"
          class="dynamic-info"
        >
          <div class="dynamic-left">
            <img :src="item.u_img" alt="" width="24" class="image" />
          </div>
          <div class="dynamic-right">
            <div class="dynamic-right-top">
              <div>
                <span class="text-sty1">
                  {{ item.u_name }}
                </span>
                <!-- <span class="text-sty" @click="reply('name')">回复</span> -->
                <span class="text-sty" @click="editOpen(item)">编辑</span>
                <span class="text-sty" @click="setRemove(item)">撤回</span>
              </div>
              <span class="dynamic-time">{{ item.time }}</span>
            </div>
            <div class="dynamic-right-bottom" v-show="item.state == 1">
              {{ item.content }}
            </div>
            <FileImg :fileList="item.file" v-show="item.state == 3" />
            <File :fileList="item.file" v-show="item.state == 4" />
          </div>
        </div>
      </div>
      <div id="base"></div>
      <div v-show="chatInfo.length == 0">暂无消息记录</div>
    </div>
    <div class="dynamic-text">
      <ul class="pop-up" v-show="stateChange">
        <li v-for="item in options" :key="item.id" @click="selectUser(item)">
          {{ item.name }}
        </li>
      </ul>
      <el-input
        @keyup.native="setAt($event)"
        id="inputText"
        resize="none"
        type="textarea"
        :rows="2"
        placeholder="请输入对话内容"
        v-model="form.content"
      >
      </el-input>
      <div class="dynamic-text-bottom">
        <div class="icon-list">
          <div class="text-icon" @click="fileOpen">
            <i class="el-icon-paperclip"></i>
          </div>
          <div class="text-icon" @click="imgOpen">
            <i class="el-icon-picture-outline"></i>
          </div>
        </div>
        <!-- <el-link :underline="false" type="primary" @click="seachUser()"
          >获取</el-link
        > -->
        <el-link :underline="false" type="primary" @click="setAdd()"
          >发送</el-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { number } from 'echarts'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getAdd, getList, getEdit, getWithdraw, getEit } from '@/api/chat'
import FileImg from '@/components/Task/chat/fileImg.vue'
import File from '@/components/Task/chat/file.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { FileImg, File },
  props: {
    pid: Number,
  },
  data() {
    //这里存放数据
    return {
      stateChange: false,
      options: [],
      value: '',
      fileList: [],
      innerVisibleImg: false,
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      innerVisible: false,
      record: {
        id: '',
        content: '',
      },
      form: {
        pid: '',
        state: 1,
        file: [],
        uid: [],
        content: '',
      },
      getInfo: {
        pid: '',
        entire: 0,
      },
      chatInfo: [],
    }
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
    fileUrl() {
      return this.$store.state.fileUrl
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    scrollIntoView(id) {
      let element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }
    },
    async setAll() {
      if (this.getInfo.entire == 0) {
        this.getInfo.entire = 1
      } else {
        this.getInfo.entire = 0
      }
      let info = await getList(this.getInfo)
      this.chatInfo = info.data.data
      // this.scrollIntoView("base");
    },
    setAt(e) {
      console.log(e)
      if (e.code == 'Digit2' && e.shiftKey == true) {
        this.stateChange = true
      }
    },
    selectUser(item) {
      var textapp = document.getElementById('inputText')
      let cursor = textapp.selectionStart
      let at = item.name + ' '
      var newStr =
        this.form.content.slice(0, cursor) +
        at +
        this.form.content.slice(cursor)
      this.form.content = newStr
      this.stateChange = false
    },
    seachUser() {
      var arrAt = this.form.content.match(/@{1}([\u4e00-\u9fa5]|\w)+\s{1}/g)
      if (arrAt) {
        var at = []
        for (var i = 0; i < arrAt.length; i++) {
          var username = arrAt[i].replace('@', '').trim() //取@开头空格结尾的中间的文本，也就是@的那个人的昵称
          console.log(username)
          const that = this
          // that.options.filter(function (obj) {
          //   if (obj.name == username) {
          //     at.push(obj.id);
          //   }
          // });
          console.log(that.options)
          that.options.forEach((item) => {
            if (item.name == username) {
              at.push(item.id)
            }
          })
          that.form.uid = at
        }
      }
    },

    imgOpen() {
      this.innerVisibleImg = true
      this.form.state = 3
    },
    fileOpen() {
      this.innerVisibleImg = true
      this.form.state = 4
    },
    imgClose() {
      this.form.state = 1
    },
    //编辑
    editOpen(item) {
      this.innerVisible = true
      this.record.id = item.id
      this.record.content = item.content
    },
    async setEdit() {
      let info = await getEdit(this.record)
      this.judge(info)
    },
    //撤回
    async setRemove(item) {
      console.log(item)
      let info = await getWithdraw({ id: item.id })
      this.judge(info)
    },
    //获取聊天记录
    async setAdd() {
      this.seachUser()
      let info = await getAdd(this.form)
      this.judge(info)
    },
    async setList() {
      let info = await getList(this.getInfo)
      this.chatInfo = info.data.data
      this.scrollIntoView('base')
    },
    async setEit() {
      let info = await getEit({ id: this.pid })
      this.options = info.data.data
    },
    exceed() {
      this.$message({
        message: '数量超出',
        type: 'warning',
      })
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      this.form.file.push(response.data.url)
    },
    //移除图片的事件
    imgRemove(file, fileList) {
      this.form.file = []
      fileList.forEach((item) => {
        if (item.response) {
          this.form.file.push(item.response.data.url)
        } else {
          this.form.file.push(item.url)
        }
      })
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.setList(this.getInfo)
        this.form = { pid: this.pid, state: 1, file: [], content: '' }
        // 123123
        this.fileList = []
        this.innerVisible = false
        this.innerVisibleImg = false
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return false
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // let text = document.getElementById("inputText");
    // let that = this;
    // text.onkeyup = function (e) {
    //   if (
    //     (e.keyCode == "50" && e.shiftKey == true) ||
    //     (e.keyCode == "229" && e.shiftKey == true)
    //   ) {
    //     that.stateChange = true;
    //   }
    // };

    this.getInfo.pid = this.pid
    this.form.pid = this.pid
    this.setList()
    this.setEit()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.dynamic {
  height: 300px;
  overflow: auto;
  margin-bottom: 10px;
}
.dynamic-info {
  width: 100%;
  display: flex;
  padding-bottom: 5px;
  position: relative;
  border-radius: 5px;
  box-sizing: border-box;
  transition: all 0.2s;
}
.dynamic-left {
}
.dynamic-right {
  flex: 1;
}
.dynamic-right-top {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.dynamic-right-bottom {
  width: 90%;
  white-space: pre-wrap;
  text-indent: -4px;
}
.dynamic-time {
  color: #8c8c8c;
  font-size: 12px;
}
.dynamic-text {
  position: relative;
}
.dynamic-text-bottom {
  display: flex;
  justify-content: space-between;
}
.dynamic-info:hover .text-sty {
  display: inline-block;
}
.image {
  margin: 0 8px 0 5px;
  padding: 2px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee;
  vertical-align: middle;
}
.circle {
  width: 100%;
  height: 200px;
  overflow: auto;
}
.circle-info {
  width: 40%;
  float: left;
  margin-right: 10px;
  margin-left: 15px;
}
.text-icon {
  font-size: 18px;
  cursor: pointer;
  color: #8c8c8c;
}
.text-icon:hover {
  color: #409eff;
}
.text-sty {
  color: #595959;
  font-size: 12px;
  cursor: pointer;
  display: none;
  margin-left: 10px;
}
.text-sty1 {
  font-size: 14px;
  color: black;
}
.text-sty:hover {
  color: #409eff;
}
.el-dialog__wrapper >>> {
  overflow: hidden;
}
.card-score {
  display: inline-block;
  color: #fff;
}
.card-score-title {
  color: #fff;
  text-indent: 10px;
}
.card-score-all {
  background: #e4d59e;
  border: 1px solid #e4d59e;
  border-radius: 5px;
  padding: 5px 0px;
}
.box-card {
  width: 300px;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.more {
  display: flex;
  justify-content: center;
  align-items: center;
}
.more-text {
  color: #409eff;
  cursor: pointer;
  user-select: none;
}
.icon-list {
  display: flex;
}
.icon-list > div {
  margin-right: 5px;
}
.pop-up {
  list-style: none;
  position: absolute;
  -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 0;
  top: -91px;
  left: 0;
  right: 0;
  width: 98%;
  bottom: 22px;
  margin: 0 auto;
  background-color: #fff;
  overflow-x: hidden;
  height: 90px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  z-index: 99;
}
.pop-up li {
  padding: 2px 2px 2px 5px;
  cursor: pointer;
}
.pop-up li:hover {
  background: #ebebeb;
}
.pop-up li.selected {
  background: #ebebeb;
}
</style>
